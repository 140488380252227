import { APIClient } from "smartassess-api-lib";
const apiClient = new APIClient();
const applicantToken: any = localStorage.getItem("applicantToken");
apiClient.initializeApiClient(
  `${process.env.REACT_APP_API}`,
  applicantToken,
  false,
  () => {
    // Redirect to the login page
    window.location.href = "/";
    APIClient.setApiToken("");
    localStorage.clear();
  },
);
export default apiClient;

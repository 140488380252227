import { useEffect, useRef, useState } from "react";
import CustomModal from "../CustomModal/CustomModal";
import { AssessmentButton } from "ui-components";
import "./PlayVoice.scss";
import { useTranslation } from "react-i18next";
const PlayVoice = ({ qNo, qType, qMins, onAudioEnd, isPlaying }: any) => {
  const audioFiles = [
    `${process.env.PUBLIC_URL}/utils/Audio/displaying_question_number.mp3`,
    `${process.env.PUBLIC_URL}/utils/Audio/count_voice/${qNo}.mp3`,
    `${process.env.PUBLIC_URL}/utils/Audio/${qType}_type.mp3`,
    `${process.env.PUBLIC_URL}/utils/Audio/counts_with_min_voice/${qMins}_minutes.mp3`,
    `${process.env.PUBLIC_URL}/utils/Audio/kindly_record_your_answer.mp3`,
  ];
  const audioIndexRef = useRef(0);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const hasStartedRef = useRef(false); // Track if playback has already started
  const [permissionAudioPopup, setPermissionAudioPopup] = useState(false);
  const { t } = useTranslation();
  const playNextAudio = () => {
    if (audioIndexRef.current < audioFiles.length) {
      audioRef.current = new Audio(audioFiles[audioIndexRef.current]);
      audioRef.current.playbackRate = 1.5;
      audioRef.current
        .play()
        .then(() => {
          audioRef.current!.onended = () => {
            audioIndexRef.current += 1;
            playNextAudio();
          };
        })
        .catch((error) => {
          console.error("Playback failed:", error);
          if (error.name === "NotAllowedError") {
            setPermissionAudioPopup(true);
          }
        });
    } else if (onAudioEnd) {
      onAudioEnd();
      hasStartedRef.current = false;
    }
  };
  useEffect(() => {
    if (!isPlaying || hasStartedRef.current) return;
    hasStartedRef.current = true; // Mark as started to prevent reruns
    audioIndexRef.current = 0;
    playNextAudio();
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
      hasStartedRef.current = false;
    };
  }, [isPlaying]);

  return (
    <CustomModal
      show={permissionAudioPopup}
      isHeaderVisible={false}
      isFooterVisible={false}
      isCrossBtn={false}
      className="audiopermissionModal"
    >
      <span className="audioPermissionText">{t("GENERAL.ALLOW_AUDIO")}</span>
      <div className="popupBtns">
        <AssessmentButton
          onClick={() => {
            playNextAudio();
            setPermissionAudioPopup(false);
          }}
        >
          {t("GENERAL.ALLOW")}
        </AssessmentButton>
        <AssessmentButton
          onClick={() => {
            setPermissionAudioPopup(false);
          }}
        >
          {t("GENERAL.DENY")}
        </AssessmentButton>
      </div>
    </CustomModal>
  );
};

export default PlayVoice;

import { useTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import { AssessmentButton, showToast, InputField } from "ui-components";
import {
  geoLocationapiClient,
  loginUserData,
} from "../../../utils/Common/Constant";
import { loginApplicant } from "../../../service/SubmitAssessment/SubmitAssessment";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import ApplicantSignInWrapper from "../../../components/ApplicantSignInWrapper/ApplicantSignInWrapper";
import { APIClient } from "smartassess-api-lib";

const ApplicantLogin: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [ipAddress, setIPAddress] = useState("");
  const [geolocationAddress, setGeolocationAddress] = useState("");
  //get ip and geolocation address
  const getIp = async () => {
    const geo = await geoLocationapiClient();
    setIPAddress(geo?.ip);
    const geoAddress = `${geo?.country_name},${geo?.region},${geo?.city}`;
    setGeolocationAddress(geoAddress);
  };
  useEffect(() => {
    getIp();
  }, []);
  // Get current language
  const currentLanguage = i18n.language;
  const navigate = useNavigate();

  // login User Schema
  const loginUserSchema = Yup.object().shape({
    email: Yup.string()
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, t("ERROR.INVALID_EMAIL_ADDRESS"))
      .required(t("ERROR.EMAIL_REQUIRED")),
    password: Yup.string()
      .min(6, t("ERROR.PASSWORD_MUST"))
      .trim()
      .required(t("ERROR.PASSWORD_REQUIRED")),
  });

  // login applicant
  const handleApplicantLogin = async (
    value: any,
    resetForm?: () => void,
    setSubmitting?: (data: boolean) => any,
  ) => {
    const data = {
      emailId: value?.email?.toLowerCase(),
      password: value?.password.trim(),
      userType: "applicant",
      IP: ipAddress || "192.192.168.92",
      deviceToken: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9",
      agent: "web",
      loginType: "custom",
      location: geolocationAddress || "Indore",
    };
    try {
      // Set isSubmitting to true to indicate that form submission is in progress
      setSubmitting && setSubmitting(true);
      const sendInvitationRes = await loginApplicant(data);
      if (sendInvitationRes?.data?.status === 200) {
        localStorage.clear();
        resetForm && resetForm();
        localStorage.setItem(
          "applicantToken",
          sendInvitationRes?.data?.data?.accessToken,
        );
        APIClient.setApiToken(sendInvitationRes?.data?.data?.accessToken);
        localStorage.setItem("emailId", value?.email);
        localStorage.setItem(
          "applicantId",
          sendInvitationRes?.data?.data?.applicantId,
        );
        navigate(`/${currentLanguage}/submitassessment`);
      } else {
        showToast(sendInvitationRes?.data?.customMessage, "error");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      // Ensure isSubmitting is set to false, even if an error occurs during submission
      setSubmitting && setSubmitting(false);
    }
  };

  return (
    <ApplicantSignInWrapper>
      <Formik
        initialValues={loginUserData}
        validationSchema={loginUserSchema}
        onSubmit={(value, { resetForm, setSubmitting }) => {
          handleApplicantLogin(value, resetForm, setSubmitting);
        }}
      >
        {({ errors, touched, values, setFieldValue, isSubmitting }) => {
          return (
            <Form>
              <div className="userContent">
                <h1 className="userHeadingApp">{t("GENERAL.WELCOME")}</h1>
                <h1 className="userHeading">{t("GENERAL.CANDIDATE_LOGIN")}</h1>
                <div>
                  <Field name="email">
                    {() => (
                      <InputField
                        placeholder={`${t("GENERAL.EMAIL_ADDRESS")} *`}
                        onChange={(newValue: any) =>
                          setFieldValue("email", newValue.trim())
                        }
                        value={values.email}
                        additionalProps={{ className: "emailApplicantLogin" }}
                      />
                    )}
                  </Field>
                  {errors?.email && touched?.email ? (
                    <div className="errorMessage">{errors?.email}</div>
                  ) : null}

                  <Field name="password">
                    {() => (
                      <div className="passwordSec">
                        <InputField
                          placeholder={`${t("GENERAL.PASSWORD")} *`}
                          onChange={(newValue) =>
                            setFieldValue("password", newValue)
                          }
                          value={values.password}
                          additionalProps={{
                            className: "passwordApplicantLogin",
                          }}
                          type="password"
                        />
                      </div>
                    )}
                  </Field>
                  {errors?.password && touched?.password ? (
                    <div className="errorMessage">{errors?.password}</div>
                  ) : null}
                </div>
                <div
                  className="forgotPasswordText"
                  onClick={() =>
                    navigate(`/${currentLanguage}/forgot-password`)
                  }
                >
                  <span>{t("GENERAL.FORGOT_PASSWORD")}</span>
                </div>
                <AssessmentButton
                  className="submitBtn"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? t("GENERAL.LOADING") : t("GENERAL.SIGN_IN")}
                </AssessmentButton>
              </div>
            </Form>
          );
        }}
      </Formik>
    </ApplicantSignInWrapper>
  );
};
export default ApplicantLogin;

import { useTranslation } from "react-i18next";
import CustomModal from "../../../components/CustomModal/CustomModal";
import "./InstructionModal.scss";
import { AssessmentButton, CheckboxRadio } from "ui-components";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
export interface IinstructionModal {
  instruction: any;
  show: boolean;
  handleClose: any;
  handleShow: any;
}
const InstructionModal: React.FunctionComponent<IinstructionModal> = ({
  instruction,
  show,
  handleClose,
  handleShow,
}) => {
  const { t } = useTranslation();
  const [checkTerms, setCheckTerms] = useState(false);
  return (
    <CustomModal
      show={show}
      size="xl"
      handleClose={handleClose}
      handleShow={handleShow}
      isHeaderVisible={false}
      isCrossBtn={true}
      isFooterVisible={false}
    >
      <div className="headerDesign"></div>
      <div className="headerDesignText">Assessment Instructons</div>

      <div className="instructionModalContent mt-5">
        {instruction !== "undefined" && (
          <p
            className="assessmentDetailsInstruction"
            dangerouslySetInnerHTML={{
              __html: `${instruction}`,
            }}
          />
        )}
        <div className="terms_Condition_check mt-5 mb-2">
          <CheckboxRadio
            type={"checkbox"}
            checked={checkTerms}
            onChange={() => setCheckTerms(!checkTerms)}
          />
          <span className="terms_Condition_text">{`${t("GENERAL.ACCEPT_PROCEED")}`}</span>{" "}
          {/* &nbsp; */}
          {/* <span className="terms_Condition_text">{`${t("GENERAL.ACCEPT_TERMS_AND_CONDITIONS")}`}</span> */}
        </div>
        <Modal.Footer>
          <div className="modalFooter">
            <div className="closeButton">
              <AssessmentButton onClick={handleClose} disabled={!checkTerms}>
                {t("GENERAL.ACCEPT")}
              </AssessmentButton>
            </div>
          </div>
        </Modal.Footer>
      </div>
    </CustomModal>
  );
};

export default InstructionModal;
